
import Vue from 'vue'
import Component from 'vue-class-component'
import { RawLocation } from 'vue-router'
import { getModule } from 'vuex-module-decorators'

import OrganizationUpgradeMenu from '@/modules/organization/components/upgrade-menu.vue'

import { OrganizationModule } from '../store'

@Component({
  components: {
    OrganizationUpgradeMenu
  },
  name: 'OrganizationNavbar'
})
export default class extends Vue {
  organizationModule = getModule(OrganizationModule, this.$store)

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.$params.organizationId)!
  }

  get currentOrganizationPermission () {
    return this.organizationModule.organizationPermission(this.currentOrganization)
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get team () {
    return this.$queryValue('team')
  }

  get tabs () {
    const tabs: { id: string, name: string, link?: RawLocation }[] = [
      {
        id: 'ownerview',
        link: {
          name: 'organization-manage-overview'
        },
        name: 'Overview'
      },
      {
        id: 'users',
        link: {
          name: 'organization-manage-members'
        },
        name: 'Organization members'
      },
      {
        id: 'billing',
        link: {
          name: 'organization-manage-billing'
        },
        name: 'Plans & billing'
      }
    ]

    if (this.currentOrganizationPermission === 'admin') {
      tabs.push(
        {
          id: 'diagramming',
          link: {
            name: 'organization-manage-diagramming'
          },
          name: 'Diagramming'
        },
        {
          id: 'sharing',
          link: {
            name: 'organization-manage-sharing'
          },
          name: 'Sharing'
        },
        {
          id: 'ownership-teams',
          link: this.currentOrganizationLimits.teams ? { name: 'organization-manage-teams', query: this.team ? { team: this.team } : undefined } : undefined,
          name: 'Ownership teams'
        },
        {
          id: 'integrations',
          link: {
            name: 'organization-manage-integrations'
          },
          name: 'Integrations'
        },
        {
          id: 'api-keys',
          link: {
            name: 'organization-manage-api-keys'
          },
          name: 'API keys'
        }
      )
    }

    return tabs
  }

  get teamsTabIndex () {
    return this.tabs.findIndex(o => o.id === 'ownership-teams')
  }
}
