
import Vue from 'vue'
import Component from 'vue-class-component'
import { Location } from 'vue-router'
import { getModule } from 'vuex-module-decorators'

import { RouteModule } from '@/modules/route/store'

import Navbar from '../components/navbar.vue'
import { OrganizationModule } from '../store'

@Component({
  components: {
    Navbar
  },
  name: 'OrganizationManage'
})
export default class extends Vue {
  organizationModule = getModule(OrganizationModule, this.$store)
  routeModule = getModule(RouteModule, this.$store)

  get currentOrganizationId () {
    return this.$params.organizationId
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get currentOrganizationPermission () {
    return this.organizationModule.organizationPermission(this.currentOrganization)
  }

  get backLink (): Location {
    const lastDashboardRoute = this.routeModule.history.find(o => o.name && !o.name.startsWith('organization-') && !o.name.startsWith('azure-devops-') && !o.name.startsWith('bitbucket-') && !o.name.startsWith('github-') && !o.name.startsWith('gitlab-'))
    if (lastDashboardRoute?.name) {
      return {
        name: lastDashboardRoute.name,
        params: lastDashboardRoute.params,
        query: lastDashboardRoute.query
      }
    } else if (this.currentOrganizationId) {
      return {
        name: 'landscapes',
        params: {
          organizationId: this.currentOrganizationId
        }
      }
    } else {
      return {
        name: 'organizations'
      }
    }
  }
}
